import React from "react";
import Carousel from "nuka-carousel";
import "../styles/Banner.css";

import quitesuadivida from "../assets/banner/quitesuadivida.png";
import limpanome from "../assets/banner/limpanome.jpg";
import alienacao from "../assets/banner/alienfiduciaria.jpg";
import buscaeapreensao from "../assets/banner/buscaeapreensao.jpg";
import recuperacaoveicul from "../assets/banner/recuperacaoveicul.jpg";
import Analise from "../assets/banner/Analise.jpg";
import cnh from "../assets/banner/cnh.jpg";
import jurosabusivos from "../assets/banner/jurosabusivos.jpg";
// import quitacarro from "../assets/banner/quitacarro.jpg";
export default function Banner() {
  return (
    <Carousel
      className="mt_50"
      speed={200}
      defaultControlsConfig={{
        nextButtonText: "Prox.",
        prevButtonText: "Prev.",
        pagingDotsStyle: {
          fill: "white",
        },
      }}
      autoplay={true}
      wrapAround={true}
      transitionMode="scroll3d"
      heightMode="current"
    >
      <img src={quitesuadivida} alt="Quite sua Divida" />
      <img src={limpanome} alt="Limpa Nome" />
      <img src={alienacao} alt="Alienação" />
      <img src={buscaeapreensao} alt="Busca e apreensao"/>
      <img src={cnh} alt="CNH"/>
      <img src={jurosabusivos}alt="Juros abusivos" />
      <img src={Analise} alt="Analise Contratual" />
      {/* <img src={quitacarro} alt="quita carro"/> */}
      <img src={recuperacaoveicul} alt="Recuperação de Veiculo"/>
    </Carousel>
  );
}
