import React, { useState, Component } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import { Layout } from "./components/Layout";
import { Home } from "./components/page/Home";
import { LimpaNome } from "./components/page/Limpanome";
import { QuitaSuaDivida } from "./components/page/QuitaDivida";
import { Alienacao } from "./components/page/Alienacao";
import { BuscaApreencao } from "./components/page/BuscaApreencao";
import { RecuperaCNH } from "./components/page/RecuperaCNH";
import { JurosAbusivos } from "./components/page/JurosAbusivos";
import { AnaliseContratual } from "./components/page/AnaliseContratual";
import { RecuperaVeiculo } from "./components/page/RecuperaVeiculo";

export class App extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/servico/nome-limpo" component={LimpaNome} />
          <Route path="/servico/quita-sua-divida" component={QuitaSuaDivida} />
          <Route path="/servico/alienacao-fiduciaria" component={Alienacao} />
          <Route path="/servico/busca-apreencao" component={BuscaApreencao} />
          <Route path="/servico/recupera-cnh" component={RecuperaCNH} />
          <Route path="/servico/juros-abusivos" component={JurosAbusivos} />
          <Route path="/servico/analise-contratual" component={AnaliseContratual} />
          <Route path="/servico/recuperacao-de-veiculos" component={RecuperaVeiculo} />
        </Switch>
      </BrowserRouter>
    );
  }
}
