import React from "react";
import { Container, Col, Row, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/footer.css";
import logo from "../assets/logo-h.png";
import amplafer from "../assets/selo-ampla.jpeg";
import {
  FaInstagram,
  FaFacebookF,
  FaLinkedin,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";

function Footer() {
  return (
    <>
      <div className="bg_footer">
        <Container>
          <Row>
            <Col className="mt-5" md={5}>
              {/* <img src={logo} width="200px" alt="Logo Ampla" /> */}
              <div
                style={{
                  marginTop: "100px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <img src={amplafer} className="img-fluid" style={{ borderRadius: "10px" }} alt="Logo Ampla" />
              </div>
              <p style={{ marginTop: "3rem" }} className="text-break text-white">
                <b>CNPJ:</b> 41.384.833/0001-80 <br />
                <b>Endereço: </b>{" "}
                <a
                  style={{ color: "white" }}
                  href="https://goo.gl/maps/o15EMGKUykW3qVR47"
                >
                  São Paulo-SP CEP 03065-020 <br />
                  R. Restinga, n°113 <br />
                  Conjunto 1415 <br />
                  Edifício Empresarial Axis-Businnes Tower Tatuapé
                </a>
                <br />
                <b>Telefone: </b>{" "}
                <a href="tel:1120980637">(11) 2098-0637 |</a>

                <a href="https://wa.me/5511911117279?text=Olá" target="_blank" rel="noreferrer"> (11) 91111-7279 </a>
              </p>
            </Col>
            <Col className="titulo_footer " md={4}>
              <h3>Siga-nos em nossas mídias sociais 
                  para obter dicas sobre soluções financeiras.
                </h3>
              
              <ListGroup horizontal className="mb-4">
                <ListGroup.Item
                  className="icon instagram"
                  action
                  target="_blank"
                  href="https://www.instagram.com/amplaconsultoria.financeira/"
                >
                  <FaInstagram style={{ color: "white" }} className="icon-10" />
                </ListGroup.Item>
                <ListGroup.Item
                  className="icon"
                  action
                  style={{ backgroundColor: "#3b5998" }}
                  target="_blank"
                  href="https://www.facebook.com/profile.php?id=100088076950159&sk=about"
                >
                  <FaFacebookF style={{ color: "white" }} className="icon-10" />
                </ListGroup.Item>
                <ListGroup.Item
                  className="icon"
                  action
                  style={{
                    backgroundColor: "#25d366"
                  }}
                  target="_blank"
                  href="https://wa.me/5511911117279?text=Olá"
                >
                  <FaWhatsapp
                    style={{ color: "#fff" }}
                    className="icon-10" />

                </ListGroup.Item>

              </ListGroup>
            </Col>
            <Col md={3} >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.800013377592!2d-46.58272568502262!3d-23.539694284693617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5ec4c571cb15%3A0x219ad0953a329332!2sR.%20Restinga%2C%20113%20-%20Tatuap%C3%A9%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2003065-020!5e0!3m2!1spt-BR!2sbr!4v1620053605112!5m2!1spt-BR!2sbr"
                style={{
                  marginTop: "80px",
                  border: "none;",
                  width: "100%",
                  height: "200px"
                }}
                allowfullscreen=""
                loading="lazy"></iframe>
            </Col>
          </Row>
        </Container>
        <div className="bg_footer-bottom">
          <Container>
            <p className="text-center text-white p-4">
              Ampla Consultoria Financeira Copyright &copy; {new Date().getFullYear()} - Todos os
              Direitos estão Reservados -{" "}
              <a href="https://www.amplaconsultoriafinanceira.com.br">
                www.amplaconsultoriafinanceira.com.br
              </a>
              <br />
              Desenvolvido por{" "}
              <a href="https://www.agenciahunters.com">Agência Hunters</a>
            </p>
          </Container>
        </div>
      </div>
    </>
  );
}
export default Footer;
