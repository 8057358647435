import React, { Component } from "react";
import Carousel from "nuka-carousel";
import { Col, Container, Row } from "react-bootstrap";
import FormContato from "../../components/FormContato";
import data from "../../Service/data";
import alienacaofeduciaria from "../../assets/servico/3Alienacaofeduciaria.png";
import "../../styles/Banner.css"
import { Layout } from "../Layout";
import alienacaoMobile from "../../assets/banner/mobile/3-Alienacaofiduciaria.png"

export class Alienacao extends Component {
  render() {
    return (
      <>
        <Layout >
          <Carousel
            className="mt_50 banner-desktop"
            speed={200}
            defaultControlsConfig={{
              nextButtonText: "",
              nextButtonStyle: {
                display: "none"
              },
              prevButtonText: "",
              prevButtonStyle: {
                display: "none"
              },
              pagingDotsStyle: {
                fill: "white",
              },
            }}
            autoplay={true}
            wrapAround={true}
            transitionMode="fade"
            heightMode="current"
          >
            <img src={alienacaofeduciaria} alt="alienacaofeduciaria" />
          </Carousel>
          <div className="banner-mobile">
            <img className="img-fluid" src={alienacaoMobile} alt="Alienação" />
          </div>
          <Container style={{
            marginBottom: "50px"
          }} >
            <Row className="mt-5">
              {data
                .filter(
                  (e) =>
                    e.Title ===
                    "O Que é Alienação Fiduciária ?"
                )
                .map((result) => (
                  <>
                    <Col id={result.id}>
                      <h3>{result.Title}</h3>
                      <p className="text-break">
                        <result.Description />
                      </p>
                    </Col>
                    <Col md="4"className="bg_form">
                      <FormContato type={result.type} />
                    </Col>
                  </>
                ))}
            </Row>
          </Container>
        </Layout>
      </>
    );
  }
}
