import React, { Component, useState } from "react"
import { Form, Col, Container, Button } from "react-bootstrap"
import data from "../Service/data";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";


export default function FormFooter() {
    const MySwal = withReactContent(Swal);
    const [validated, setValidated] = useState(false);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [_phone, _setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [servico, setServico] = useState("");
    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const handleServicoChange = (event) => {
        setServico(event.target.value)
    }
    const handlePhoneChange = (event) => {
        const mask = phoneMask(event.target.value);
        console.log(mask.length);
        setPhone(mask);
    };
    const _handlePhoneChange = (event) => {
        const mask = phoneMask(event.target.value);
        console.log(mask);
        _setPhone(mask);
    };
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const removeMask = (value) => {
        return value.replace(/\D/g, "");
    };
    const phoneMask = (value) => {
        const val = removeMask(value);
        return val.replace(/(\d{2})(\d{5})(\d{4})/g, "($1) $2-$3");
    };
    const handleSubmit = (event) => {

        event.preventDefault();
        const form = event.currentTarget;
       
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true)
        

        if(form.checkValidity()) submitForm();
        
    }
    const submitForm = () => {
        Swal.showLoading();
        fetch("../../api/contato.php", {
            method: "POST",
            body: JSON.stringify({
                name,
                phone,
                _phone,
                email,
                servico,
            })
        }).then((result) => {
            console.log(result);
            MySwal.fire({
                title: "",
                text: result.status == 200 ? "Email enviado" : "Servico indisponivel",
                icon: result.status == 200 ? "success" : "warning",
            });
        }).catch(error => console.log(error));

    }
    return (
        <div style={{
            marginTop: "100px",
            backgroundColor: "#bfdae2",
            padding: "20px"
        }}>
            <Container>
                <div className="text-center">
                    <h3 className="font-weight-bold">NÓS ENTRAMOS EM CONTATO COM VOCÊ</h3>
                </div>
                <Form noValidate validated={validated} onSubmit={handleSubmit} className="mt-5">
                    <Form.Row>
                        <Form.Group as={Col} md="6">
                            <Form.Control
                                type="text"
                                required={true}
                                onChange={handleNameChange}
                                placeholder="Seu Nome:"></Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Form.Control
                                type="email"
                                required={true}
                                onChange={handleEmailChange}
                                placeholder="Seu Email:"></Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Form.Control
                                type="text"
                                required={true}
                                value={phone}
                                onChange={handlePhoneChange}
                                placeholder="Telefone"></Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Form.Control
                                type="text"
                                value={_phone}
                                onChange={_handlePhoneChange}
                                placeholder="Telefone 2 (Opcional)"></Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Form.Control
                                required={true}
                                onChange={handleServicoChange} as="select" custom>
                                <option value="">Selecione</option>
                                {data.map((item, index) =>
                                    (<option value={item.type} key={index}>{item.menu}</option>)
                                )}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Form.Control
                                required={true}
                                as="textarea"
                                minLength="30"
                                rows={3}>
                            </Form.Control>
                        </Form.Group>
                        <Col md="6">
                            <Button variant="primary" type="submit" >
                                Enviar
                            </Button>
                        </Col>
                    </Form.Row>
                </Form>
            </Container >
        </div>
    );
}
