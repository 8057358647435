import React, { Component } from "react";
import Carousel from "nuka-carousel";
import { Col, Container, Row } from "react-bootstrap";
import FormContato from "../../components/FormContato";
import data from "../../Service/data";
import buscaapreencao from "../../assets/servico/4Buscaeapr_deveiculo.png";
import buscaapreencaoMobile from "../../assets/banner/mobile/4-Buscaeapree_deveiculos.png";
import { Layout } from "../Layout";

export class BuscaApreencao extends Component {
  render() {
    return (
      <Layout>
        <Carousel
          className="mt_50 banner-desktop"
          speed={200}
          defaultControlsConfig={{
            nextButtonText: "",
            nextButtonStyle: {
              display: "none"
            },
            prevButtonText: "",
            prevButtonStyle: {
              display: "none"
            },
            pagingDotsStyle: {
              fill: "white",
            },
          }}
          autoplay={true}
          wrapAround={true}
          transitionMode="fade"
          heightMode="current"
        >
          <img src={buscaapreencao} alt="buscaapreencao" />
        </Carousel>
        <div className="banner-mobile">
          <img className="img-fluid" src={buscaapreencaoMobile} alt="buscaapreencao" />
        </div>
        <Container style={{
            marginBottom: "50px"
          }}>
          <Row className="mt-5">
            {data
              .filter(
                (e) =>
                  e.key === "buscaveiculo"
              )
              .map((result) => (
                <>
                  <Col id={result.id}>
                    <h3>{result.Title}</h3>
                    <p className="text-break">
                      <result.Description />
                    </p>
                  </Col>
                  <Col md="4"className="bg_form">
                    <FormContato type={result.type} />
                  </Col>
                </>
              ))}
          </Row>
        </Container>
      </Layout>
    );
  }
}
