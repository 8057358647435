import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../styles/QuemSomos.css";
import quemsomos from "../assets/banner/Quemsomosbg.png";

export default function QuemSomos() {
  return (
    <>

      <Container style={{
        marginBottom: "100px"
      }} className="mt-5">
        {/* <h3>Ampla Consultoria Financeira</h3> */}
        <Row>
          <Col sm="12" md="5" style={{ marginRight: "50px" }}>
            <img src={quemsomos} className="img-fluid" />
          </Col>
          <Col md="6" sm="12">
            <h1 className="decoration_title">Quem Somos</h1>
            <p className="text-break">             
              A Ampla Consultoria Financeira é uma Empresa Especializada em Negociação de Dívidas Bancárias com mais de 10 anos de Experiência! <br />
              Criamos a nossa própria plataforma de negociação e nos tornamos únicos em qualidade e eficiência na forma que trabalhamos.<br />
              Temos um INSUPERÁVEL método de Processos 100% ADMINISTRATIVOS para quitação de contratos oferecendo assim uma AMPLA variedade de serviços em Soluções financeiras como: Limpa nome e CNPJ/ recuperação do Crédito Score no mercado consumidor, redução de dívidas, prevenção de busca e apreensão de veículos entre outros. A nossa principal atuação é na Revisão de Juros abusivos e negociação de contratos bancários para a quitação de saldo devedor junto às instituições financeiras, conquistando assim uma redução de até 80% do valor da dívida.<br />
              Com isso recuperamos os créditos e a Auto-estima dos nossos clientes AMPLIANDO possibilidades para uma saúde financeira estável.
          </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}
