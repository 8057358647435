import React, { Component } from "react";
import Carousel from "nuka-carousel";
import { Col, Container, Row } from "react-bootstrap";
import FormContato from "../../components/FormContato";
import data from "../../Service/data";
import Quitesuadivida from "../../assets/servico/1Quitesuadivida.png";
import QuitesuadividaMobile from "../../assets/banner/mobile/1-Quitesuadivida.png";
import { Layout } from "../Layout";

export class QuitaSuaDivida extends Component {
  render() {
    return (
      <Layout>
        <Carousel
          className="mt_50 banner-desktop"
          speed={200}
          defaultControlsConfig={{
            nextButtonText: "",
            nextButtonStyle: {
              display: "none",
            },
            prevButtonText: "",
            prevButtonStyle: {
              display: "none",
            },
            pagingDotsStyle: {
              fill: "white",
            },
          }}
          autoplay={true}
          wrapAround={true}
          transitionMode="fade"
          heightMode="current"
        >
          <img id="quitadivida" src={Quitesuadivida} alt="Quite sua Divida" />
        </Carousel>
        <div className="banner-mobile">
          <img className="img-fluid" src={QuitesuadividaMobile} alt="Quite sua Divida" />
        </div>
        <Container style={{
          marginBottom: "50px"
        }}>
          <Row className="mt-5">
            {data
              .filter((e) => e.key === "quitesuadivida")
              .map((result) => (
                <>
                  <Col id={result.id}>
                    <h3>{result.Title}</h3>
                    <p className="text-break">
                      <result.Description />
                    </p>
                  </Col>
                  <Col
                    md="4"                    
                    className="bg_form"
                  >
                    <FormContato type={result.type} />
                  </Col>
                </>
              ))}
          </Row>
        </Container>
      </Layout>
    );
  }
}
