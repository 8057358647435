import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";

import Footer from "../components/Footer";
import ButtonWhatsapp from "../components/ButtonWhatsapp";
import logo from "../assets/logo-h.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/Tab.css";
import {
    Navbar,
    Nav
} from "react-bootstrap";
import data from "../Service/data";
import "../styles/Menu.css";

export class Layout extends Component {
    
    render() {

        return (
            <>
                {
                    window.scrollTo(0, 0)
                }
                <Navbar bg="dark"
                    className="transparent"
                    fixed="top"
                    expand="xl"
                    variant="dark">
                    <Navbar.Brand href="/">
                        <img src={logo} id="logoimg" alt="logoimg" className="logo-brand" />
                    </Navbar.Brand>
                    <Navbar.Toggle ontog aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="d-flex justify-content-end">
                            {data.map((result, i) => (
                                <Nav.Item key={i}>
                                    <Link to={result.link} className="nav-link">
                                        {result.menu}
                                    </Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                {this.props.children}
                <ButtonWhatsapp />
                <Footer />
            </>

        );
    }

}