const data = [
       {
              id: "quitesuadivida",
              menu: "Quite suas Dívidas",
              link: "/servico/quita-sua-divida",
              key: "quitesuadivida",
              type: "QuitesuaDivida",
              Title: "Financiamentos/ Cartão de Crédito/ Cheque/ Empréstimos Bancários.",
              Description: () => (
                     <>
                            Quite suas dívida com até 80% de desconto no saldo devedor. A
                            grande sacada da análise contratual é gerar incomodo no Polo
                            Passivo da Ação (Banco/Financeira). Para isso, nosso Escritório
                            dispõe de profissionais devidamente qualificados para agir e
                            trazer os resultados esperados quando o assunto é revisão
                            contratual do financiamento do seu veículo. Analisando
                            friamente, hoje podemos dizer que não depende do resultado da
                            Análise Contratual para obter sucesso.
                            <br />
                            <br />
      Porém a Análise Contratual é imprescindível e devemos sim
      ingressar com a mesma discutindo as Cláusulas Contratuais e suas
      Taxas de Juros quando estas forem consideradas acima da média do
      mercado.
                            <br />
                            <br />
      Isto posto, conseguimos gerar incomodo, sendo assim serão
      aplicadas nas Negociações Extrajudiciais manobras técnicas e
      jurídicas onde a Ampla Consultoria Financeira consegue chegar a
      Propostas de Acordo para Quitação Integral do Contrato com
      desconto de até 80% no Saldo Devedor, ou seja, no que falta
      pagar do Contrato. Sendo assim, meu caro cliente, podemos lhe
      afirmar sucesso na Contratação do nosso Escritório.
                     </>
              )

       },
       {
              id: "nomelimpo",
              type: "Nome limpo",
              link: "/servico/nome-limpo",
              menu: "Nome limpo",
              key: "nomelimpo",
              Title: "Limpa nome/CNPJ / Restauração de Score / Blindagem.",
              Description: () => (
                     <>
                            Você tem restrições no seu CPF e CNPJ e por esse motivo não
                            consegue linha de crédito, devido ao baixo relacionamento com o
                            mercado do crédito consumidor?
                            <br />
                            <br />
                            Nós da Ampla Consultoria Financeira temos a Solução e uma Ampla
                            oportunidade de Reabilitar o seu CPF, CNPJ e Alavancar seu score
                            Através de três passos.
                            <br />
                            <br />
                            1- Retirando todos apontamentos e restrições dos órgãos de
                            proteção ao crédito em até 45 dias.
                            <br />
                            <br />
                            2- Restaurando o seu poder de compra alavancando o seu score.
                            <br />
                            <br />
                            3- Ainda de Bônus Blindamos seu CPF e CNPJ No período de 12
                            meses para sua tranquilidade e da sua empresa. Deixa que nossa
                            equipe garante que seus negócios cresçam sem impedimentos.
                            <br />
                            <br />
                            <h3>Aumento de Score Físico e Jurídico</h3>
                            <br />
                            <br />
                            É o serviço para que possui o Rating abaixo da zona verde, ou seja score na zona vermelha, o que o impossibilita de obter crédito bancário, cartões, empréstimos, financiamentos automotivos e muito mais. Trabalhamos para que seu score se torne positivo nas plataformas de consulta, lhe fornecendo a chance de crédito livre novamente.
                            <b>Lembrando que para a execução do mesmo, é imprescindível que o nome físico ou Jurídico não possua apontamentos.</b>
                            <br />
                            <br />
                            <h3><b>3 EM 1 (Aumento de Score, Retirada de Apontamentos e blindagem)</b><br /> (tanto físico como jurídico)</h3>
                            <br />
                            <br />
                            Utilizamos esse serviço nas plataformas de consulta de instituições financeiras e particulares como Serasa Experian e Boa Vista, retiramos os apontamentos que constam atualmente, possibilitando sua entrada como cliente positivo em todos os âmbitos de crédito pessoal. Ao mesmo tempo
                            <b> Blindamos </b>
                            seu nome, a Blindagem ocorre no seu nome ou de sua empresa pelo período de 1 ano, onde não constaram apontamentos futuros durante essa <b>blindagem.</b>
                            O aumento de Score também está incluso nesse serviço, onde deixamos seu nome ou de sua empresa na zona positiva para facilidade na liberação de crédito.
                     </>
              )
       },
       {
              id: "alienacao",
              type: "Alienação Fiduciária",
              link: "/servico/alienacao-fiduciaria",
              menu: "Alienação Fiduciária",
              key: "alienacao",
              Title: "O Que é Alienação Fiduciária ?",
              Description: () => (
                     <>
                            Alienação fiduciária se trata da transferência de bens como
                            garantia para realizar o pagamento de uma dívida, a partir de um
                            acordo estabelecido entre o credor e o devedor. Quando colocada
                            em prática a alienação fiduciária o credor toma para si o bem
                            acordado como garantia, por vias jurídicas.
                            <br />
                            <br />
     A alienação Fiduciária faz com que o credor tenha maior
     segurança com relação ao pagamento do devedor. No Brasil esse
     tipo de transação é comum como meio para financiar um veículo.
     Quando vai financiar um veículo o cliente assina um contrato com
     o banco ou financiadora, ele coloca como garantia o próprio
     veículo e passa a ter dívidas com essa instituição. Segundo os
     trâmites do contrato, o carro se torna propriedade do cliente
     apenas quando o veículo é totalmente quitado.
                            <br />
                            <br />
     Até que a última parcela seja paga, o veículo é propriedade do
     banco ou financeira, podendo ocorrer assim a busca e apreensão,
     em caso de atraso de parcela. Quando feita uma alienação
     fiduciária e o devedor não cumpre com suas obrigações, o credor
     pode tomar para si o bem alienado, porém não pode ficar com o
     bem, deve vende-lo ou leiloa-lo para que a operação seja
     finalizada.
                            <br />
                            <br />
                            Você está com dificuldade de pagar os seus bens alienados?  <br />
                            Não perca e não devolva o seu veículo ou seus bens alienados para as instituições financeiras!
                            <br />
                            <br />
                            A AMPLA CONSULTORIA FINANCEIRA pode te ajudar! Nós temos a melhor proposta!
                            <br />
                            Compramos o seu bem alienando, assumimos toda a a dívida do seu  financiamento, te reabilitamos no mercado do crédito consumidor com seu nome limpo, score restaurado e com dinheiro no bolso!
                            <br />
                            <br />
                            Assim você ficará livre de toda sua dívida.


                     </>
              )
       },
       {
              id: "buscaveiculo",
              type: "Busca e Apreensão de Veículos",
              menu: "Busca e Apreensão de Veículos",
              link: "/servico/busca-apreencao",
              key: "buscaveiculo",
              Title: "O que é Busca e Apreensão de Veículos ?",
              Description: () => (
                     <>
                            A busca e apreensão de veículos acontece quando um cliente que
                            possui um veículo deixa de cumprir suas obrigações com relação
                            ao pagamento das parcelas de financiamento. Sendo assim, um Juiz
                            toma a decisão de apreender o veículo em questão, visando
                            cumprir o que foi negociado em contrato.
                            <br />
                            <br />
     Saiba que esse tipo de ação é legal e prevista em lei. Ela
     permite que o banco ou a financiadora pegue o bem de volta caso
     você não esteja fazendo a quitação da transação financeira.
                            <br />
                            <br />
     Os contratos de busca e apreensão possuem cláusulas de alienação
     fiduciária, onde o contratante adquire um bem móvel, e se
     compromete a seguir todos os termos, incluindo o pagamento de
     parcelas sem atrasos.
                            <br />
                            <br />
     É importante deixar claro que aplicar a busca e apreensão não é
     precisamente a primeira coisa que o banco quer. O que acontece
     no primeiro momento é de o consumidor procurar o banco para
     negociar a dívida, alongando o prazo de pagamento ou reduzindo o
     valor das parcelas.
                            <br />
                            <br />
     Porém, se você não agir dessa maneira, então o banco não terá
     outra opção senão ajuizar uma ação de busca e apreensão de
     veículo justamente para não ficar com o prejuízo.
                     </>)
       },
       {
              id: "recuperacaodecnhsuspensaoucassada",
              type: "Recuperação de CNH suspensa ou cassada",
              link: "/servico/recupera-cnh",
              menu: "Recuperação de CNH",
              key: "recuperaCNH",
              Title: "O que é recuperação de CNH suspensa ou cassada?",
              Description: () => (
                     <>
                            É REALMENTE POSSÍVEL VOLTAR A DIRIGIR COM A CNH EM PROCESSO DE SUSPENSÃO?
                            <br />
                            <br />
     SIM!!! Havendo uma defesa em andamento o condutor
     automaticamente tem o direito de voltar a dirigir segundo a
     Resolução 723/CONTRAN, enquanto aguarda o julgamento da mesma,
     visando o cancelamento de sua penalidade.
                            <br />
                            <br />
     QUAIS CUIDADOS DEVO TOMAR?
                            <br />
                            <br />
     Não faça entrega da sua Carteira de Habilitação, caso isso
     realmente não tenha acontecido.
                            <br />
                            <br />
     Sobre CNH Suspensa: O motorista que atingir 20 pontos em sua
     CNH, dentro do período de 12 meses, ou cometer uma infração auto
     suspensiva poderá ter o seu direito de dirigir SUSPENSO por um
     longo tempo.
                            <br />
                            <br />
     A Lei n.º 13.281/16 elevou a pena mínima da suspensão, de 01 mês
     para 06 meses.
                            <br />
                            <br />
     Meio ano sem poder dirigir representa um enorme prejuízo para
     qualquer pessoa, mais ainda se estivermos falando de um taxista,
     caminhoneiro ou qualquer outro profissional que precisa da CNH
     válida e do direito de dirigir irrestrito para poder sustentar
     sua família.
                            <br />
                            <br />
     A AMPLA possui em seu currículo diversos clientes que foram
     multados, cassados e suspensos e acabaram beneficiados com a
     nossa assessoria.
                            <br />
                            <br />
     Venha, fale com a gente. Veja quantas vantagens.
                     </>
              )
       },
       {
              id: "jurosabusivos",
              type: "Juros Abusivos",
              link: "/servico/juros-abusivos",
              menu: "Juros Abusivos",
              key: "jurosabusivos",
              Title: "O que são Juros Abusivos ?",
              Description: () => (
                     <>
                            Juros Abusivos é aquilo que a justiça entende como juros que se
                            diferenciam de forma exacerbada dos juros médios cobrados pelo
                            mercado. Para compreender melhor se os juros de um financiamento
                            são abusivos, é necessário realizar uma análise contratual do
                            seu financiamento.
                            <br />
                            <br />
     Os juros abusivos acontecem principalmente com aqueles que
     possuem um prazo extenso de parcelas, é necessário se perguntar
     se o valor cobrado pelas financeiras e bancos são justos e se
     estão de acordo com as leis. Os juros abusivos são identificados
     em contratos que contém irregularidades ou provocam prejuízos
     consideráveis.
                            <br />
                            <br />
     Juros que ultrapassam os valores praticados no mercado,
     geralmente não são difíceis de se identificar, desde que um
     profissional seja consultado. Quando a partir de uma análise de
     contrato, os profissionais competentes compreendem que há juros
     abusivos em um financiamento, é possível negociar essa dívida e
     baixa-la de modo considerável.
                     </>
              )
       },
       {
              id: "analisecontratual",
              type: "Analise",
              link: "/servico/analise-contratual",
              menu: "Analise Contratual",
              key: "analisecontratual",
              Title: "Analise Contratual",
              Description: () => (
                     <>
                            Análise contratual de veículos é uma avaliação para identificar
                            e validar cláusulas de um contrato. Essa análise tem como
                            finalidade a redução ou eliminação de uma dívida, funciona de
                            modo a identificar práticas abusivas de financeiras ou bancos.
                            <br />
                            <br />
     Feita por um profissional competente pode
     causar benefícios como alteração de prazos e valores e
     identificação de práticas ilícitas dos bancos e financeiras como
     o juros abusivos por exemplo, trazendo processos para essas
     organizações por danos morais e repetição de indébito.
                            <br />
                            <br />
     Avaliamos os veículos para identificar
     e validar cláusulas de um contrato. Essa análise tem como
     finalidade a redução ou eliminação de uma dívida, funciona de
     modo a identificar práticas abusivas de financeiras ou bancos.
                     </>)
       },
       {
              id: "recuperacaodeveiculos",
              type: "recupercao",
              link: "/servico/recuperacao-de-veiculos",
              menu: "Recuperação de veículos ",
              key: "recuperacaodeveiculos",
              Title: "Recuperação de veículos",
              Description: () => (
                     <>
                            Meu carro foi apreendido e não consegui recuperar, o que fazer?
Medidas administrativas e judiciais existem para os casos em que você esteja passando pelo processo de busca e apreensão de veículo. Saiba que elas servem para reverter esse tipo de ação.<br />
                            <br />
                            A apreensão de um veículo é a consequência de uma irregularidade que o condutor cometeu, fazendo com que esse ato gere uma forma de penalidade para o motorista.                       <br />
                            <br />
                            Vale lembrar, que a apreensão e remoção são duas coisas distintas. A apreensão é realizada mediante um processo, ou seja, uma penalidade. A remoção é feita no ato, pois geralmente o automóvel esteja dificultando seu percurso, ou por, algo administrativo.               <br/>
                            
                            
                            <br/> Para fazer a captação de um veículo é necessário um processo legal, no qual irá ser decidido o tempo que o mesmo ficará retido, sendo assim, não é algo imediato, leva um tempo para a lei decidir esse ato, pois é necessário comprovar a irregularidade do condutor. Quando a apreensão acontece, o custo de tudo que for necessário, como aluguel do pátio, fica como responsabilidade do infrator.</>)
       }
];
export default data;