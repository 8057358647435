import React, { Component } from "react";
import "../styles/Servico.css";
import { Link } from "react-router-dom";
import jurosabusivos from "../assets/icon/jurosabusivos.png";
import analisecontratual from "../assets/icon/analisecontratual.png";
import { Container, Row, Col } from "react-bootstrap";
import "../styles/Servicos.css";
import quiteDivida from "../assets/icon/servicos/1-Quitedivida.png"
import nomeLimpo from "../assets/icon/servicos/2-Nomelimpo.png"
import alienacaoFinducia from "../assets/icon/servicos/3-alienacaofiduciaria.png"
import buscaeApreencao from "../assets/icon/servicos/4-Buscaeaprdeveiculo.png"
import recuperaCnh from "../assets/icon/servicos/5-Recuperacaocnh.png"
import jurosAbusivos from "../assets/icon/servicos/6-jurosabusivos.png"
import analiseContratual from "../assets/icon/servicos/7-analisecontratual.png";
import recuperaVeiculo from "../assets/icon/servicos/8-Recuperacaodeveiculo.png";
import bgAmpla from "../assets/banner/bgAmpla.png"

export class Servico extends Component {
  render() {
    return (
      <>
        {/* <div
          id="servicos"
          className="bgservice"
        ></div> */}
        <div className="bgservice text-center text-white">
          <h3 className="center">
            SERVIÇOS
          </h3>
        </div>
        <Container className="mb_mobile mb-5">
          <Row>
            <Col md="4">
              <Link to="/servico/quita-sua-divida">
                <div className="expand-service" style={{
                  marginTop: "50px",
                  backgroundImage: `url(${quiteDivida})`,
                  padding: "10px",
                  height: "300px",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "30px"
                }}>
                </div>
              </Link>
            </Col>
            <Col md="4">
              <Link to="/servico/nome-limpo">
                <div className="expand-service" style={{
                  marginTop: "50px",
                  backgroundImage: `url(${nomeLimpo})`,
                  padding: "10px",
                  height: "300px",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "30px"
                }}>
                </div>
              </Link>
            </Col>
            <Col md="4">
              <Link to="/servico/alienacao-fiduciaria">
                <div className="expand-service" style={{
                  marginTop: "50px",
                  backgroundImage: `url(${alienacaoFinducia})`,
                  padding: "10px",
                  height: "300px",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "30px"
                }}>
                </div>
              </Link>
            </Col>
            <Col md="4">
              <Link to="/servico/busca-apreencao">
                <div className="expand-service" style={{
                  marginTop: "50px",
                  backgroundImage: `url(${buscaeApreencao})`,
                  padding: "10px",
                  height: "300px",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "30px"
                }}>
                </div>
              </Link>
            </Col>
            <Col md="4">
              <Link to="/servico/recupera-cnh">
                <div className="expand-service" style={{
                  marginTop: "50px",
                  backgroundImage: `url(${recuperaCnh})`,
                  padding: "10px",
                  height: "300px",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "30px"
                }}>
                </div>
              </Link>
            </Col>
            <Col md="4">
              <Link to="/servico/juros-abusivos">
                <div className="expand-service" style={{
                  marginTop: "50px",
                  backgroundImage: `url(${jurosAbusivos})`,
                  padding: "10px",
                  height: "300px",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "30px"
                }}>
                </div>
              </Link>
            </Col>
            <Col md="4">
              <Link to="/servico/analise-contratual">
                <div className="expand-service" style={{
                  marginTop: "50px",
                  backgroundImage: `url(${analiseContratual})`,
                  padding: "10px",
                  height: "300px",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "30px"
                }}>
                </div>
              </Link>
            </Col>
            <Col md="4">
              <Link to="/servico/recuperacao-de-veiculos">
                <div className="expand-service" style={{
                  marginTop: "50px",
                  backgroundImage: `url(${recuperaVeiculo})`,
                  padding: "10px",
                  height: "300px",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "30px"
                }}>
                </div>
              </Link>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
