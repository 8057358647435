import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function FormContato({ type }) {
  const MySwal = withReactContent(Swal);
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [servico, setServico] = useState(type);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }
    setValidated(true);
    submitForm();
  };
  const submitForm = () => {
    fetch("/api/contato.php", {
      method: "POST",
      body: JSON.stringify({
        name,
        phone,
        email,
        servico,
      }),
    }).then((result) => {
      MySwal.fire({
        title: "",
        text: result.status == 200 ? "Email enviado" : "Servico indisponivel",
        icon: result.status == 200 ? "success" : "warning",
      });
    });
  };
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handlePhoneChange = (event) => {
    const mask = phoneMask(event.target.value);
    console.log(mask.length);
    setPhone(mask);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const removeMask = (value) => {
    return value.replace(/\D/g, "");
  };
  const phoneMask = (value) => {
    const val = removeMask(value);
    return val.replace(/(\d{2})(\d{5})(\d{4})/g, "($1) $2-$3");
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Control required type="hidden" value={servico} />
      <Form.Group>
        <Form.Label>Nome</Form.Label>
        <Form.Control
          required
          type="text"
          onChange={handleNameChange}
          placeholder="Nome"
        />
        <Form.Control.Feedback></Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Telefone</Form.Label>
        <Form.Control
          required
          type="text"
          value={phone}
          maxLength="15"
          onChange={handlePhoneChange}
          placeholder="Telefone"
        />
        <Form.Control.Feedback></Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Email</Form.Label>
        <Form.Control
          required
          type="email"
          onChange={handleEmailChange}
          placeholder="Email"
        />
        <Form.Control.Feedback></Form.Control.Feedback>
      </Form.Group>
      <Button type="submit">Enviar</Button>
    </Form>
  );
}
export default FormContato;
