import React from "react";
import { Component } from "react";
import QuemSomos from "../QuemSomos";
import { Servico } from "../Servico";
import Banner from "../Banner";
import { Layout } from "../Layout";
import { NossosDiferenciais } from "../NossosDiferenciais";
import FormFooter from "../FormFooter";

export class Home extends Component {
    render() {
        return (
            <>
                <Layout>
                    <Banner />
                    <QuemSomos />
                    <Servico />
                    <NossosDiferenciais />
                    <FormFooter />
                </Layout>
            </>)
    }
}