import React from "react";
import { FaWhatsapp } from "react-icons/fa";

function ButtonWhatsapp() {
  return (
    <a
      href="https://wa.me/5511911117279?text=Olá"
      style={{
        position: "fixed",
        width: "60px",
        height: "60px",
        bottom: "20px",
        left: "20px",
        // right: "40px",
        backgroundColor: "#25d366",
        color: "#FFF",
        borderRadius: "50px",
        textAlign: "center",
        fontSize: "35px",
        boxShadow: "1px 1px 20px #888",
        zIndex: "1000",
      }}
      target="_blank" rel="noreferrer"
    >
      <FaWhatsapp style={{ color: "white" }} />
    </a>
  );
}
export default ButtonWhatsapp;
