import React, { Component, Fragment } from "react"
import { Col, Container, Row } from "react-bootstrap";
import buroCracia from "../assets/icon/Diferencias/Iconessite-03.png"
import focoResultado from "../assets/icon/Diferencias/Iconessite-01.png"
import eficienciaComprovada from "../assets/icon/Diferencias/Iconessite-02.png"
import focoCliente from "../assets/icon/Diferencias/Iconessite-04.png"
import "../styles/Diferencias.css";
export class NossosDiferenciais extends Component {
    render() {
        return (
            <Fragment>
                <div className="bgservice text-center text-white">
                    <h3 className="center">
                        NOSSOS DIFERENCIAIS
                    </h3>
                </div>
                <Container className="desktop" >
                    <Row style={{
                        textAlign: 'center',
                    }}>
                        <Col md="3">
                            <img className="icon-service" src={buroCracia} height="170" />
                            <h5 className="mt-4">SEM BUROCRACIA</h5>
                            <p className="text-break">
                                Faça uma simulação através do nosso site e entraremos em contato com você rapidamente!
                            </p>
                        </Col>
                        <Col md="3">
                            <img className="icon-service" src={focoResultado} height="170" />
                            <h5 className="mt-4">FOCADA EM RESULTADOS</h5>
                            <p className="text-break">
                                Focada em trazer resultados reais para os seus clientes, dentro das expectativas propostas pelos mesmos
                            </p>
                        </Col>
                        <Col md="3">
                            <img className="icon-service" src={eficienciaComprovada} height="170" />
                            <h5 className="mt-4">EFICÁCIA COMPROVADA</h5>
                            <p className="text-break">
                                Temos como objetivo, superar as expectativas de nossos clientes assegurando qualidade, confiabilidade e eficiência de nossos serviços.
                            </p>
                        </Col>
                        <Col md="3">
                            <img className="icon-service" src={focoCliente} height="155" />
                            <h5 style={{
                                marginTop: "2rem"
                            }}>FOCO NO CLIENTE</h5>
                            <p className="text-break">
                                Maior agilidade a fim de satisfazer os clientes e alcançar o resultado esperado.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }

}